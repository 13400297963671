import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { SettingsFields } from '../../../../types/emuns/settingsFields';
import { ISettingsForm } from '../../../../types/data/settingsForm';

interface IUnitSelectComponentProps {
    label: string;
}

const UnitSelectComponent: React.FunctionComponent<IUnitSelectComponentProps> = (props) => {
    const { label } = props;

    const { setFieldValue } =
        useFormikContext<ISettingsForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(SettingsFields.UNITS, value as string)
    }
    const units = [
        {
            label: "FT",
            value: 'ft'
        },
        {
            label: "M",
            value: 'm'
        }
    ]
    return <div className='form-field'>
        <p className='form-field-label'>{label}</p>
        <SelectComponent
            name={SettingsFields.UNITS}
            spaceWidth={100}
            selectWidth={100}
            options={units}
            onChange={changeHandler} withError/>
    </div>
};

export default React.memo(UnitSelectComponent);
