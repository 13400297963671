import * as React from 'react';
import './index.scss';
import InputWithCheckbox from '../../../inputs/InputWithCheckbox';
import publicMark from "../../../../images/icons/legend/public.png";
import verificationMark from "../../../../images/icons/legend/badge.png";
import { IContactDetailsTranslations } from '../../../../types/responses/translations/profileTranslations';
import { IProfileInitialValues } from '../../../../types/data/ProfileInitialValues';
import { useFormikContext } from 'formik';
import { StoreContext } from '../../../../store/StoreProvider';
interface IContactDetailsProps {
  translations: IContactDetailsTranslations
}

const ContactDetails: React.FunctionComponent<IContactDetailsProps> = (props) => {
  const { values, errors, setValues } = useFormikContext<IProfileInitialValues>();
  const { store } = React.useContext(StoreContext);
  const { translations } = props;
  const addPhone = () => {
    const phones = [...values.phones, { phone: '', visible: false }]
    setValues({ ...values, phones });
  }
  const removePhone = (index: number) => () => {
    const phones = [...values.phones]
    phones.splice(index, 1);
    setValues({ ...values, phones });
  }
  return <div className='contacts module line'>
    <h2 className='contacts-title title'>{translations.title}</h2>
    <div className='contacts-inputs'>
      <div className='input-checkbox-wrapper'>
        {
          <InputWithCheckbox
            name={'email'}
            type={'email'}
            images={[publicMark, verificationMark]}
            checkboxName='email_visible'
            label={translations.email}
            placeholder={translations.email_placeholder}
            inputImage={<i className="fa-regular fa-envelope"></i>}
            checkboxLabel={translations.checkbox}
            defaultChecked={store.user ? store.user.email_visible : false} />

        }
      </div>
      <div className="label-wrapper">
        <span className="label-text">
          {translations.phone}
        </span>
        {[publicMark, verificationMark].map(image => <img src={image} alt="" className="label-image" key={image}/>)}</div>
      {values.phones.length < 1 &&
        <div>
          {
            errors && errors.phones && (<span className='error_msg'>{'Need to pass at least one phone number'}</span>)
          }
        </div>
      }
      {
        values.phones && values.phones.map((el, i) => {
          return <div key={i} className='input-checkbox-wrapper'>
            <InputWithCheckbox
              name={`phones[${i}].phone`}
              type={'tel'}
              placeholder={translations.phone_placeholder}
              onDelete={removePhone(i)}
              showDeleteBtn={true}
              checkboxLabel={translations.checkbox}
              checkboxName={`phones[${i}].visible`}
              defaultChecked={el.visible}
              inputImage={<i className="fa-solid fa-mobile-screen-button"></i>} />
          </div>
        })
      }

      <div className='contacts-add-btn-wrapper' onClick={addPhone}>
        <i className="fa-solid fa-plus"></i>
        <span className='contacts-add-btn'>{translations.add_phone_btn}</span>
      </div>
    </div>
  </div>;
};

export default ContactDetails;
