import * as React from 'react';
import { IPointsOfInterestsTranslations } from '../../../../types/responses/translations/advertisementTranslations';
import { useFormikContext } from 'formik';
import { IAdvInitialValues } from '../../../../types/data/advInitialValues';
import SelectComponent from '../../../select/SelectComponent';
import NumberInputWithUnit from '../../../inputs/NumberInputWithUnit';
import './points.scss'
import { AdvFields } from '../../../../types/emuns/advfields';
import { IPointsOfInterests } from '../../../../types/data/generalConfigs';
interface IPointOfInterestsModuleProps {
    translations: IPointsOfInterestsTranslations;
    options: IPointsOfInterests[];
}

const PointOfInterestsModule: React.FunctionComponent<IPointOfInterestsModuleProps> = (props) => {
    const { translations, options } = props
    const { values, errors, setValues } = useFormikContext<IAdvInitialValues>();
    const [pointOptions, setPointOptions] = React.useState(options);

    const addPoint = () => {
        const points_of_interest = [...values.points_of_interest, { point: '', distance: '', unit: 'm' }]
        setValues({ ...values, points_of_interest });
    }
    const removePoint = (index: number) => () => {
        const points = [...values.points_of_interest]
        points.splice(index, 1);
        setValues({ ...values, points_of_interest: points });
        const leftedOptions = options.filter((a) => !points.some(b => b.point === a.name))
        setPointOptions(leftedOptions);

    }
    const onPointsChangeHandler = (index: number) => (value: string | string[]
    ) => {
        const points = [...values.points_of_interest];
        points[index].point = value as string;
        setValues({ ...values, points_of_interest: points })
        // const leftedOptions = options.filter((a) => !points.some(b => {
        //   if (b.point === a.id.toString()) {
        //     return a.name
        //   }
        // }))
        // console.log(leftedOptions);
        
        // setPointOptions(leftedOptions)
    };
    return <div className='points module line'>
        <h2 className='points-title title'>{translations.title}</h2>
        <div className='points-inputs'>
            {
                values.points_of_interest && values.points_of_interest.map((_, index) => {
                    return (
                      <div className="points-inputs-wrapper" key={index}>
                        <div className="select-wrapper">
                          <p className='label-text'>
                            {translations.label}
                          </p>
                          <SelectComponent
                            name={`${AdvFields.POINTS_OF_INTEREST}[${index}].point`}
                            value={values.points_of_interest[index].point}
                            onChange={onPointsChangeHandler(index)}
                            spaceWidth={100}
                            selectWidth={100}
                            options={pointOptions.map((option) => {
                              return { label: option.name, value: option.id.toString() };
                            })}
                            direction="vertical"
                            withError
                          />
                        </div>
                        <NumberInputWithUnit
                          name={`${AdvFields.POINTS_OF_INTEREST}[${index}].distance`}
                          label={translations.distance_label}
                          unitName={`${AdvFields.POINTS_OF_INTEREST}[${index}].unit`}
                          withError
                        />
                        <div
                          className="points-btn-wrapper delete-btn"
                          onClick={removePoint(index)}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                          <span className="points-btn">
                            {translations.delete_btn}
                          </span>
                        </div>
                      </div>
                    );
                })

            }

            <div className='points-btn-wrapper' onClick={addPoint}>
                <i className="fa-solid fa-plus"></i>
                <span className='points-btn'>{translations.add_btn}</span>
            </div>

        </div>
    </div>;
};

export default PointOfInterestsModule;
