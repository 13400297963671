import { useField, useFormikContext } from "formik";
import * as React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IAdvSearchForm } from "../../types/data/advSearchForm";
interface ITextAreaProps {
  name: string;
  label?: string;
  className?: string;
  image?: string;
}

const TextArea: React.FunctionComponent<ITextAreaProps> = (props) => {
  const { name, label, className, image } = props;
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext<IAdvSearchForm>();
  const onChange = async (value: string) => {
    setFieldValue(name, value);
  }
  return (
    <div className={`text-area-wrapper ${className ?? ""}`}>
      <p>
        {label} {image ? <img src={image} alt="" /> : ""}
      </p>
      {meta.touched && meta.error && (
        <span className="error_msg">{meta.error}</span>
      )}
      <ReactQuill theme="snow" value={field.value} onChange={onChange} />
    </div>
  );
};

export default TextArea;
