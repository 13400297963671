import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { IAdvSearchForm } from '../../../../types/data/advSearchForm';

interface IAdvListPaginationProps {
    label: string;
    field: string
    onChange?: (fieldName: string, value: string) => void

}

const AdvListPagination: React.FunctionComponent<IAdvListPaginationProps> = (props) => {
    const { label, field, onChange } = props;
    const { setFieldValue } =
        useFormikContext<IAdvSearchForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(field, +value)
        if (onChange) {
            onChange(field, value as string)
        }
    }
    const pagination = [
        { label: '10', value: '10' },
        { label: '20', value: '20' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
    ]
    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{label}</p>
        <SelectComponent
            defaultValue={'10'}
            name={field}
            spaceWidth={100}
            selectWidth={100}
            options={pagination}
            onChange={changeHandler} />
    </div>
};

export default AdvListPagination;
