import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { SettingsFields } from '../../../../types/emuns/settingsFields';
import { ISettingsForm } from '../../../../types/data/settingsForm';
import { BASE_PATH } from '../../../../api/axios';
import { ICountry } from '../../../../types/data/generalConfigs';

interface ICountrySelectComponentProps {
    config: ICountry[];
    label: string;
}

const CountrySelectComponent: React.FunctionComponent<ICountrySelectComponentProps> = (props) => {
    const { config, label } = props;
    const { setFieldValue, values } =
        useFormikContext<ISettingsForm>();
    const changeHandler = async (value: string | string[]) => {
        await setFieldValue(SettingsFields.COUNTRY, value as string)
    }
    const countries = React.useMemo(()=>config.map((el) => { return { label: el.name, value: el.code2 } }), [config]);
    return <div className='form-field'>
        <div className='form-field-label'>
            <img src={BASE_PATH + '/' + config.find(el=>el.code2 === values[SettingsFields.COUNTRY])?.flag} alt="" className='label-icon'/>
            {label}
        </div>
        <SelectComponent
            name={SettingsFields.COUNTRY}
            spaceWidth={100}
            selectWidth={100}
            options={countries}
            onChange={changeHandler} withError />
    </div>
};

export default React.memo(CountrySelectComponent);
