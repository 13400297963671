import * as React from "react";
import Unit from "../unit/Unit";
import NumInput from "./NumInput";

interface INumberInputWithUnitDoubleProps {
    label?: string;
    nameFirst: string;
    nameSecond: string;
    withError?: true;
    id?: string;
    image?: string;
    phFirst?: string;
    phSecond?: string;
    unitValue?: string;
    unitName: string;
    className?: string;
    area?: true;
    onChange?: (fieldName: string, value: string, unit?: string) => void

}

const NumberInputWithUnitDouble: React.FunctionComponent<
    INumberInputWithUnitDoubleProps
> = (props) => {
    const { label, nameFirst, nameSecond, image, unitName, withError, area, phFirst, phSecond, onChange } = props;
    return (
        <div className={`number-input-with-unit double-input ${withError ? "with-error" : ""}`}>
            <label className={`label`}>
                <div className="label-wrapper">
                    <span className="label-text">{label}</span>{" "}
                    {image && <img src={image} alt="" className="label-image" />}
                </div>
                <div className="input-wrapper">
                    <NumInput name={nameFirst} placeholder={phFirst} onChange={onChange}/>
                    <NumInput name={nameSecond} placeholder={phSecond} onChange={onChange}/>
                </div>
            </label>
            <Unit name={unitName} area={area} onChange={onChange} />
        </div>
    );
};

export default NumberInputWithUnitDouble;
