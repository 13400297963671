import axios, { AxiosError } from "axios";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
const devMode = false;
export const BASE_PATH = devMode ? 'http://localhost:8000' : 'https://aleks-dev.tech';
export const BASE_STORAGE_PATH = devMode ? 'http://localhost:8000/storage' : 'https://aleks-dev.tech/storage';


export const instance = axios.create({
    baseURL: BASE_PATH,
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
    }
})

instance.interceptors.response.use(
    (config: any) => {
        return config
    },
    (error) => {
        const e = error as AxiosError;
        if (e.response?.status === 401) {
            window.location.href = BASE_PATH + '/login'
        }
    }
)

export async function register(data: FormData) {
    return axios.get(BASE_PATH + '/sanctum/csrf-cookie').then(() => {
        return axios({
            method: 'POST',
            url: BASE_PATH + '/api/register',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        })
    })
}

// export async function login(data: FormData) {
//     return axios.get(BASE_PATH + '/sanctum/csrf-cookie').then(() => {
//         return axios.post(BASE_PATH + "/api/login", data)
//     });
// }

// export async function loadProfileTranslations(path: string) {
//     return axios.get(BASE_PATH + path)
// }
// export async function loadAdvTranslations(path: string) {
//     return axios.get(BASE_PATH + path)
// }

// export async function loadAppData(path: string) {
//     return axios.get(BASE_PATH + path)
// }

// export async function getMultiData(path: string, data: FormData) {
//     return axios.post(BASE_PATH + path, data)
// }

