import * as React from "react";
import Main from "../layouts/Main";
import RightSidebar from "../components/navbars/RightSidebar";
import ProfileContent from "../components/content/profile/ProfileContent";
import { StoreContext } from "../store/StoreProvider";
import { IProfileTranslationData } from "../types/responses/translations/profileTranslations";
import { useProfileData } from "../hooks/useProfileData";
import Loader from "../components/loader/Loader";
import { IProfileInitialValues } from "../types/data/ProfileInitialValues";
import {
  IMainTranslationData,
  ISidebarTranslationData,
} from "../types/responses/translations/generalTranslations";
import { FormikProps } from "formik";
import { profileInitialValues } from "../validation/profile/profileShema";
import Switcher from "../components/switcher/Switcher";

interface IPersonalAreaProps { }

const PersonalArea: React.FunctionComponent<IPersonalAreaProps> = () => {
  const { store } = React.useContext(StoreContext);
  const [profileTranslations, setProfileTranslations] =
    React.useState<IProfileTranslationData | null>(null);
  const [mainTranslations, setMainTranlsations] =
    React.useState<IMainTranslationData | null>(null);
  const [sidebarTranslations, setSidebarTranlations] =
    React.useState<ISidebarTranslationData | null>(null);
  const [initialValues, setInitialValues] =
    React.useState<IProfileInitialValues>(profileInitialValues);
  const [showAllFields, setShowAllFields] = React.useState(true);
  const {
    isLoading,
    data,
    loadMultiData,
    onSubmit,
    loadImage,
    deleteImage,
    getUser,
  } = useProfileData();
  const innerRef = React.useRef<FormikProps<IProfileInitialValues>>();

  const toggleFields = () => {
    setShowAllFields(!showAllFields)
  }
  const changeHandler = async (value: string) => {
    if (value) {
      const mutable = { ...initialValues } as IProfileInitialValues;
      mutable.updated_lang = value;
      setInitialValues(mutable);
      const data = await loadMultiData(value);
      innerRef.current?.setValues({
        ...innerRef.current.values,
        name: data?.name ?? "",
        last_name: data?.last_name ?? "",
      });
    }
  };
  React.useEffect(() => {
    if (!store.user) {
      return;
    }
    const {
      image,
      name,
      last_name,
      langs,
      email,
      email_visible,
      phones,
      timezone,
    } = store.user;

    setInitialValues({
      image: image ?? '',
      name: name ?? '',
      last_name: last_name ?? '',
      langs: langs ?? [],
      email,
      email_visible,
      phones,
      timezone: timezone ?? '',
      updated_lang: store.lang
    })

  }, [store.user, store.lang]);
  React.useEffect(() => {
    if (!data) {
      return
    }
    setMainTranlsations(data.main);
    setProfileTranslations(data.profile);
    setSidebarTranlations(data.sidebar);
  }, [data]);
  return (
    <>
      <Main
        title={mainTranslations?.titleProfile}
        breadcrumbs={[
          {
            link: "/account",
            descr: mainTranslations?.breadcrumbs.home,
          },
          {
            link: "/account/profile",
            descr: mainTranslations?.breadcrumbs.profile,
          },
        ]}
      >
        {profileTranslations && (
          <ProfileContent
            showAllFields={showAllFields}
            innerRef={innerRef}
            translations={profileTranslations}
            initialValues={initialValues}
            onSubmit={onSubmit}
            getUser={getUser}
            loadImage={loadImage}
            deleteImage={deleteImage}
          />
        )}
        {sidebarTranslations && (
          <RightSidebar
            verivication={
              <Switcher label={sidebarTranslations.general.switcher_label} text={sidebarTranslations.general.switcher_text} switchFn={toggleFields} />
            }
            innerRef={innerRef}
            translations={sidebarTranslations}
            onChange={changeHandler}
            publicMark
            langMark
            indentifyMark
          />
        )}
      </Main>
      {isLoading && <Loader text={"Loading"} />}
    </>
  );
};
export default React.memo(PersonalArea);
