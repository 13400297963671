import * as React from "react";
import UploadButton from "../../../buttons/uploadbutton/UploadButton";
import ImageComp from "../../../image/ImageComp";
import { BASE_PATH } from "../../../../api/axios";
import { IPhotoTranslations } from "../../../../types/responses/translations/profileTranslations";
import { useFormikContext } from "formik";
import { IProfileInitialValues } from "../../../../types/data/ProfileInitialValues";
import { checkImage } from "../../../../utils/ckeckExtentions";
import DeleteButton from "../../../buttons/deletebutton/DeleteButton";
import { StoreContext } from "../../../../store/StoreProvider";
import { IUser } from "../../../../types/responses/user";
import './index.scss'
interface IPhotoModuleProps {
  translations: IPhotoTranslations;
  deleteImage: () => Promise<true | undefined>
  loadImage: (file: File) => Promise<any>
}

const PhotoModule: React.FunctionComponent<IPhotoModuleProps> = (props) => {
  const { translations, deleteImage, loadImage } = props;
  const { store, setUser } = React.useContext(StoreContext);
  const { values, errors, setValues, setErrors } = useFormikContext<IProfileInitialValues>();
  const [previewImage, setPreviewImage] = React.useState<
    string | ArrayBuffer | null
  >(null);

  const [error, setError] = React.useState<string>();
  const onLoadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) {
      setError("");
      const validated = checkImage(files[0], {
        size: "alowed size 10 MB",
        ext: 'alowed extentions "jpeg", "jpg", "webp", "svg"',
      });
      if (validated) {
        setError(validated);
        setErrors({ ...errors, image: validated })
        return;
      }
      const image = await loadImage(files[0])
      setPreviewImage(image);
      setValues({ ...values, image: image });
      const updated = { ...store.user } as IUser
      updated.image = image;
      setUser(updated)
    }
  };

  const onDeleteHandler = async () => {
    if (!values.image || !store.user?.image) {
      return;
    }
    setPreviewImage(null)
    const res = await deleteImage();
    if (res) {
      const user = { ...store.user as IUser }
      setUser({ ...user, image: '' })
      const mutated = { ...values }
      setValues({ ...mutated, image: '' })
    }
  }

  return (
    <div className="profile-content-inner module">
      {(typeof values.image === "string" && values.image) !== '' || previewImage ? (
        <ImageComp
          imageSrc={BASE_PATH + "/storage" + values.image}
        />
      ) : (
        <div className="empty-image">
          <i className="fa-regular fa-user"></i>
        </div>
      )}
      <div>
        {translations && (
          <>
            <h2 className="photo-title title">{translations.title}</h2>
            <p className="photo-descr">
              {translations.descr}{" "}
              <i className="fa-solid fa-circle-exclamation"></i>
            </p>
            <div className="buttons-wrapper">
              <UploadButton
                label={translations.button}
                onChange={onLoadHandler}
              />
              {
                (typeof values.image === "string" && values.image) !== '' || previewImage?
                <DeleteButton onClick={onDeleteHandler} name={'Delete image'} />
                :null
              }
            </div>
          </>
        )}
        {error && <span className="error_msg">{error}</span>}
      </div>
    </div>
  );
};

export default PhotoModule;
