import * as React from "react";
import Main from "../layouts/Main";
import { useAdvListData } from "../hooks/useAdvListData";
import AdvList from "../components/content/AdvList/AdvList";
import ControlPanel from "../components/content/AdvList/ControlPanel";
import AdvSearchForm from "../components/forms/AdvSerachForm";
import Loader from "../components/loader/Loader";
import { IAdvSearchForm } from "../types/data/advSearchForm";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { StoreContext } from "../store/StoreProvider";
import { AdvSearchFields } from "../types/emuns/adfSearchFields";
import Pagination from "../components/pagination/Pagination";

interface IAdvertisemetListProps { }

const AdvertisemetList: React.FunctionComponent<IAdvertisemetListProps> = (
  props
) => {
  const { type } = useParams();
  const didMount = React.useRef(false);
  const [target, setTarget] = React.useState("draft");

  const { store, setLangIschanged } = React.useContext(StoreContext);
  const {
    isLoading,
    data,
    listTranslations,
    mainTranslations,
    count,
    config,
    pagination,
    loadListData,
    copyAdv,
    deleteAdv,
    getListTranslations,
    publish,
  } = useAdvListData();
  const innerRef = React.useRef<FormikProps<IAdvSearchForm>>();
  const countValue = React.useMemo(() => count, [count]);
  const onSubmit = (values: IAdvSearchForm) => {
    loadListData(type as string, values);
  };
  const onCopyHandler = async (id: number) => {
    const res = await copyAdv(id);
    if (res) {
      changeHandler("draft");
    }
  };
  const changeHandler = async (status: string) => {
    await innerRef.current?.setFieldValue(AdvSearchFields.STATUS, status);
    setTarget(status);
    setTimeout(() => {
      innerRef.current?.submitForm();
    }, 0);
  };

  const onDeleteHendler = async (id: number) => {
    const res = await deleteAdv(id);
    if (res) {
      innerRef.current?.submitForm();
    }
  };

  const publishHandler = async (id: number) => {
    const res = await publish(id);
    if (res) {
      changeHandler("draft");
    }
  };
  React.useEffect(() => {
    if (!didMount.current) {
      return;
    }
    loadListData(type as string, innerRef.current?.values);
  }, [type]);
  React.useEffect(() => {
    if (store.langIsChanged) {
      loadListData(type as string);
      getListTranslations();
      setLangIschanged(false);
    }
  }, [store.langIsChanged]);
  React.useEffect(() => {
    loadListData(type as string);
    getListTranslations();
    didMount.current = true;
  }, []);
  return (
    <Main title={type === 'rent' ? mainTranslations?.titleRent : mainTranslations?.titleSell}
      breadcrumbs={[
        {
          link: "/account",
          descr: mainTranslations?.breadcrumbs.home,
        },
        {
          link: "/account/profile",
          descr: mainTranslations?.breadcrumbs.profile,
        },
        {
          link: "/account/anoucements",
          descr: mainTranslations?.breadcrumbs.annoucements,
        },
        {
          link: type === 'rent' ? "/accout/rent" : "/accout/sell",
          descr: type === 'rent'
            ? mainTranslations?.breadcrumbs.rent
            : mainTranslations?.breadcrumbs.sale,
        },
      ]}>
      <div className="adv-list-content">
        <div className="control-panel-wrapper">
          {listTranslations && config && (
            <AdvSearchForm
              innerRef={innerRef}
              config={config}
              translations={listTranslations.form}
              onClick={onSubmit}
            />
          )}
          {listTranslations && config && (
            <ControlPanel
              translations={listTranslations.list}
              config={config?.statuses}
              count={countValue}
              onChange={changeHandler}
              target={target}
            />
          )}
        </div>
        <div className="card-wrapper">
          {listTranslations && (
            <AdvList
              data={data}
              translations={listTranslations.list}
              status={listTranslations.form.status_label}
              copyFn={onCopyHandler}
              deleteFn={onDeleteHendler}
              publish={publishHandler}
            />
          )}
        </div>
        {pagination.current.data.last_page > 1 && (
          <Pagination
            pagination={pagination}
            params={innerRef.current?.values}
          />
        )}
        {isLoading && <Loader text={"Loading"} />}
      </div>
    </Main>
  );
};

export default AdvertisemetList;
