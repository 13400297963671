import * as React from "react";
import { IGeneral } from "../../../../types/responses/translations/advertisementTranslations";
import { IAdvConfig } from "../../../../types/data/advConfig";
import SelectComponent from "../../../select/SelectComponent";
import "./general.scss";
import NumberInput from "../../../inputs/NumberInput";
import { AdvFields } from "../../../../types/emuns/advfields";
import { useFormikContext } from "formik";
import { IAdvInitialValues } from "../../../../types/data/advInitialValues";

interface IGeneralModuleProps {
  translations: IGeneral;
  options: IAdvConfig;
}

const GeneralModule: React.FunctionComponent<IGeneralModuleProps> = (props) => {
  const { translations, options } = props;
  const { setFieldValue, setFieldTouched, setFieldError } =
    useFormikContext<IAdvInitialValues>();

  const onTypeChange = React.useCallback(
    () => (value: string | string[]) => {
      setFieldValue(AdvFields.PROPERTY_TYPE, value as string, false);
      setFieldTouched(AdvFields.PROPERTY_TYPE, true, false);
      setFieldError(AdvFields.PROPERTY_TYPE, undefined);
    },
    []
  );
  const onCategoryChange = React.useCallback(
    () => (value: string | string[]) => {
      setFieldValue(AdvFields.BUILD_CATEGORY, value as string, false);
      setFieldTouched(AdvFields.BUILD_CATEGORY, true, false);
      setFieldError(AdvFields.BUILD_CATEGORY, undefined);
    },
    []
  );
  return (
    <div className="general-module module line">
      <h2 className="type-title title">{translations.title}</h2>
      <div className="general-inputs-wrapper">
        <div className="general-input-wrapper">
          <p className="general-label label-text">{translations.type_label}</p>
          <SelectComponent
            name={AdvFields.PROPERTY_TYPE}
            selectWidth={100}
            onChange={onTypeChange()}
            spaceWidth={100}
            options={options.property_types.map((el) => {
              return { label: el.name, value: el.type };
            })}
            withError
          />
        </div>
        <div className="general-input-wrapper">
          <p className="general-label label-text">{translations.build_label}</p>
          <SelectComponent
            name={AdvFields.BUILD_CATEGORY}
            selectWidth={100}
            onChange={onCategoryChange()}
            spaceWidth={100}
            options={options.build_categories.map((el) => {
              return { label: el.name, value: el.category };
            })}
            withError
          />
        </div>
        <div className="general-input-wrapper">
          <NumberInput
            name={AdvFields.FLOOR}
            label={translations.floor_label}
            placeholder={translations.floor_placeholder}
            withError
          />
        </div>
        <div className="general-input-wrapper">
          <NumberInput
            name={AdvFields.YEAR}
            label={translations.year_label}
            placeholder={translations.year_placeholder}
            withError
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralModule;
