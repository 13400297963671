import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';

import { IRequestsForm } from '../../../../types/data/requestsForm';
import { RequestsFields } from '../../../../types/emuns/requestsFields';
import { IPropertyType } from '../../../../types/data/generalConfigs';

interface IRequestsResponcePersonProps {
    config?: IPropertyType[];
    label: string;
    defaultTransl: string;
    onChange?: (fieldName: string, value: string) => void
}

const RequestsResponcePerson: React.FunctionComponent<IRequestsResponcePersonProps> = (props) => {
    const { config, label, onChange } = props;

    const { setFieldValue } =
        useFormikContext<IRequestsForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(RequestsFields.RESPONSYBLE_PERSON, value as string)
        if (onChange) {
            onChange(RequestsFields.RESPONSYBLE_PERSON, value as string)
        }
    }
    const properties = config?.map((el) => { return { label: el.name, value: el.type } })

    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{label}</p>
        <SelectComponent
            name={RequestsFields.RESPONSYBLE_PERSON}
            spaceWidth={100}
            selectWidth={100}
            options={properties ?? [{ label: '', value: '' }]}
            onChange={changeHandler} />
    </div>
};

export default React.memo(RequestsResponcePerson);