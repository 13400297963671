import * as React from 'react';
import './tag.scss'
import { ITagComp } from '../../store/advSearchFormTags';
import { ITag } from '../../types/data/generalConfigs';

interface IFormTagsProps {
    config: ITag[]
    tags?: ITagComp[];
    close: (fieldName: string, i: number) => void
}

const FormTags: React.FunctionComponent<IFormTagsProps> = (props) => {
    
    const { config, tags, close } = props;
    
    return <div className='tags-wrapper'>
        {
            tags&& tags.map((tag, i) => {
                const { component: Component, ...rest } = tag;
                const props = {
                    ...rest,
                    label: config.find(el => el.tag === rest.tag)?.name,
                    close: ()=>{close(tag.tag, i)}
                }
                return <Component {...props} key={i} />
            })
        }
    </div>;
};

export default React.memo(FormTags);
