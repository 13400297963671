import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { useFormikContext } from 'formik';
import { SettingsFields } from '../../../../types/emuns/settingsFields';
import { ISettingsForm } from '../../../../types/data/settingsForm';
import { ICurrency } from '../../../../types/responses/translations';

interface ICurrencySelectComponentProps {
    config: ICurrency[];
    label: string;
}

const CurrencySelectComponent: React.FunctionComponent<ICurrencySelectComponentProps> = (props) => {
    const { config, label } = props;

    const { setFieldValue } =
        useFormikContext<ISettingsForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(SettingsFields.CURRENCY, value as string)
    }
    const currencies = config.map((el) => { return { label: el.symbol + ' ' + el.code, value: el.code } });
    return <div className='form-field'>
        <p className='form-field-label'>{label}</p>
        <SelectComponent
            name={SettingsFields.CURRENCY}
            spaceWidth={100}
            selectWidth={100}
            options={currencies}
            onChange={changeHandler} withError/>
    </div>
};

export default React.memo(CurrencySelectComponent);
