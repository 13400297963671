import * as React from 'react';
import NumberInput from '../../../inputs/NumberInput';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
import './price.scss';
interface IPriceInputProps {
    label: string
    onChange?: (fieldName: string, value: string) => void

}

const PriceInput: React.FunctionComponent<IPriceInputProps> = (props) => {
    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{props.label}</p>
        <div className="price-inputs-inner">
            <NumberInput name={AdvSearchFields.PRICE_MIN} placeholder='MIN' onChange={props.onChange}/>
            <NumberInput name={AdvSearchFields.PRICE_MAX} placeholder='MAX' onChange={props.onChange}/>
        </div>
    </div>;
};

export default PriceInput;
