import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
import { useFormikContext } from 'formik';
import { IAdvSearchForm } from '../../../../types/data/advSearchForm';
import { ICurrency } from '../../../../types/responses/translations';

interface IAdvListStatusProps {
    label: string;
    config: ICurrency[];
    onChange: (fieldName: string, value: string) => void

}

const AdvListCurrency: React.FunctionComponent<IAdvListStatusProps> = (props) => {
    const { label, config, onChange } = props;

    const { setFieldValue } =
        useFormikContext<IAdvSearchForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(AdvSearchFields.CURRENCY, value as string)
        const val = config.find(el => el.code === (value as string))
        if (val) {
            onChange(AdvSearchFields.CURRENCY, val.code)
        }
    }
    const countries = config.map((el) => ({ label:  el.symbol + ' ' + el.code, value: el.code }))
    
    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{label}</p>
        <SelectComponent
            defaultValue={'Active'}
            name={AdvSearchFields.CURRENCY}
            spaceWidth={100}
            selectWidth={100}
            options={countries}
            onChange={changeHandler}

        />
    </div>
};

export default React.memo(AdvListCurrency);
