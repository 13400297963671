import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
import { Value } from 'sass';
import { useFormikContext } from 'formik';
import { IAdvSearchForm } from '../../../../types/data/advSearchForm';
import { IPropertyType } from '../../../../types/data/generalConfigs';

interface IAdvListResponcePersonProps {
    config?: IPropertyType[];
    label: string;
    onChange: (fieldName: string, value: string) => void

}

const AdvListResponcePerson: React.FunctionComponent<IAdvListResponcePersonProps> = (props) => {
    const { config, label } = props;

    const { setFieldValue } =
        useFormikContext<IAdvSearchForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(AdvSearchFields.RESPONCE_PERSON, value as string)
    }
    const properties = config?.map((el) => { return { label: el.name, value: el.type } })

    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{label}</p>
        <SelectComponent
            name={AdvSearchFields.RESPONCE_PERSON}
            spaceWidth={100}
            selectWidth={100}
            options={properties ?? [{ label: '', value: '' }]}
            onChange={changeHandler} />
    </div>
};

export default React.memo(AdvListResponcePerson);
