import { useContext, useEffect, useState } from "react";
import { instance } from "../api/axios";
import { IMainTranslationData } from "../types/responses/translations/generalTranslations";
import { StoreContext } from "../store/StoreProvider";
import { ISettingsConfig } from "../types/data/settingsConfig";
import { ISettingsTranslations } from "../types/responses/translations/settingsTranslations";
import { ISettingsForm } from "../types/data/settingsForm";

export const useSettingsData = () => {
    const { store, setLangIschanged } = useContext(StoreContext);

    const [isLoading, setIsLoading] = useState(false);
    const [settingsTranslations, setSettingsTranslations] = useState<ISettingsTranslations>();
    const [mainTranslations, setMainTranslations] = useState<IMainTranslationData>();
    const [config, setConfig] = useState<ISettingsConfig>();
    //get translations
    const getSettingsData = async () => {
        try {
            setIsLoading(true);
            const res = await instance.get(
                 "/api/profile/settings"
            );
            if (res.data) {
                const { main, translations, sidebar } = res.data.translations;
                setMainTranslations(main);
                setSettingsTranslations({ ...translations, sidebar });
                setConfig(res.data.config)
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };
    const submit = async (params: ISettingsForm) => {
        try {

            const res = await instance.post( '/api/profile/settings/update', { params })
        } catch (error) {

        }

    }
    const deleteAccount = async () => {
        try {
            setIsLoading(true);
            const res = await instance.get('/api/profile/settings/delete');
            if (res.status === 200) {
                return true;
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getSettingsData();
    }, [])
    useEffect(() => {
        if (store.langIsChanged) {
            getSettingsData();
            setLangIschanged(false)
        }
    }, [store.langIsChanged])
    return {
        isLoading,
        settingsTranslations,
        mainTranslations,
        config,
        submit,
        deleteAccount
    };
};